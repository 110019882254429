import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/build/repo/src/components/layout/index.jsx";
import { MathJax } from 'better-react-mathjax';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Technical Writing`}</h1>
    <p><a parentName="p" {...{
        "href": "https://drive.google.com/open?id=1MZDNswTaDtk9eZ5R3LG7G5sTSrtAn4YW"
      }}>{`Download: Publication manual of the American Psychological Association (APA) `}</a></p>
    <p><a parentName="p" {...{
        "href": "https://drive.google.com/open?id=0B5kjI2-xym-PNVNtMDJWLVg2dmM"
      }}>{`Download: Engineers guide to technical writing by Kenneth G. Budinski`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation.com/wp-content/uploads/2020/03/memo-writing-instructions.docx"
      }}>{`memo writing instructions`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation.com/wp-content/uploads/2020/03/Memos.ppt"
      }}>{`Memos`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation.com/wp-content/uploads/2020/03/PARAGRAPH-WRITING.ppt"
      }}>{`PARAGRAPH WRITING`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation.com/wp-content/uploads/2020/03/resume.ppt"
      }}>{`resume`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation.com/wp-content/uploads/2020/03/technical-letters-instructions.docx"
      }}>{`technical letters instructions`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation.com/wp-content/uploads/2020/03/writing-technical-letters.ppt"
      }}>{`writing technical letters`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      